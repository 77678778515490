/*****************************************************************************/
/*
/* Common
/*
/*****************************************************************************/

/* Global Reset */
* {
    margin: 0;
    padding: 0;
}

html, body { height: 100%; }

body {
    background-color: #FFF;
    font: 13.34px Helvetica, Arial, sans-serif;
    font-size: small;
    font-feature-settings : "palt";
    text-align: center;
}

h1, h2, h3, h4, h5, h6 {
    font-size: 100%;
}

h1 { margin-bottom: 1em; }
p { margin: 1em 0; }

a         { color: #00a; }
a:hover   { color: #000; }
a:visited { color: #a0a; }

/*****************************************************************************/
/*
/* Home
/*
/*****************************************************************************/
.posts {
    list-style-type: none;
    margin-bottom: 2em;

    li {
        line-height: 1.75em;
    }

    span {
        color: #aaa;
        font-family: Monaco, "Courier New", monospace;
        font-size: 80%;
    }
}

/*****************************************************************************/
/*
/* Site
/*
/*****************************************************************************/
.site {
    font-size: 115%;
    text-align: justify;
    max-width: 640px;
    margin: 3em auto 2em;
    line-height: 1.5em;
    word-wrap: break-word;
    padding: 15px;

    h2 {
        text-align: center;
        line-height: 1.2em;
        @media screen and (max-width: 640px) {
            font-size: 25px;
        }
        @media screen and (min-width: 641px) {
            font-size: 46px;
        }
    }
}

.header {
    a {
        font-weight: bold;
        text-decoration: none;

        &.extra {
            color: #aaa;
            margin-left: 1em;

            &:hover {
                color: #000;
            }
        }
    }
}

.title {
    display: inline-block;
    margin-bottom: 1em;
    line-height: 1.2em;

    a {
        color: #a00;

        &:hover {
            color: #000;
        }
    }

    @media screen and (max-width: 640px) {
        font-size: 36px;
    }
    @media screen and (min-width: 641px) {
        font-size: 46px;
    }
}

.meta {
    color: #aaa;
    text-align: right;
}

.footer {
    font-size: 80%;
    color: #666;
    border-top: 4px solid #eee;
    margin-top: 2em;
    overflow: hidden;

    .contact {
        float: left;
        margin-right: 3em;

        a {
            color: #8085C1;
        }
    }

    .license {
        clear: both;
        text-align: center;
    }
}

/*****************************************************************************/
/*
/* Posts
/*
/*****************************************************************************/

.post {
    pre {
        /* standard */
        border: 1px solid #ddd;
        background-color: #000;
        padding: 0 .4em;
        overflow: auto;

        code {
            border: none;
        }

        &.terminal {
            /* terminal */
            border: 1px solid #000;
            background-color: #333;
            color: #FFF;

            code {
                background-color: #333;
            }
        }
    }

    ul, ol {
        margin-left: 1.35em;
    }

    code {
        border: 1px solid #ddd;
        background-color: #000;
        padding: 0 .2em;
    }

    blockquote {
        border-left: 10px solid #ddd;
        background-color: #eef;
        padding: 2em 1em .2em 1em;
        position: relative;

        &:before {
            content: "\201C";
            font-size: 6em;
            line-height: 1em;
            font-family: serif;
            color: #999;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}

.connect {
    margin: 20px auto;
}

.alert {
    background-color: #fcf8e3;
    border-color: #fbeed5;
    color: #c09853;
    padding: 1em;
    margin-bottom: 2em;
    border: 1px solid transparent;
    border-radius: 4px;
}
